import statisticsService from '@/services/http/statistics.service'

export default {
  namespaced: true,
  state: {
    // contient tout les filtres du module de recherche des statistiques
    statisticsFiltersValues: {},
    hasSubmitFilterForm: false,
  },
  getters: {
    getter_statisticsFiltersValues(state) {
      return state.statisticsFiltersValues
    },
    getter_hasSubmitFilterForm(state) {
      return state.hasSubmitFilterForm
    },
  },
  mutations: {
    setter_statisticsFiltersValues(state, data) {
      state.statisticsFiltersValues = data
    },
    setter_hasSubmitFilterForm(state, data) {
      state.hasSubmitFilterForm = data
    },
  },
  actions: {
    async getStatisticsExploitationEtablissements({ rootState }, data) {
      const res = await statisticsService.getStatisticsExploitationEtablissements(data)
      return res
    },
    async getStatisticsAgencesVoyageToursOperators({ rootState }, data) {
      const res = await statisticsService.getStatisticsAgencesVoyageToursOperators(data)
      return res
    },
    async getStatisticsTransport({ rootState }, data) {
      const res = await statisticsService.getStatisticsTransport(data)
      return res
    },
    async getStatisticsAeroports({ rootState }, data) {
      const res = await statisticsService.getStatisticsAeroports(data)
      return res
    },
    async fetchStatisticsTable({ rootState }, data) {
      const res = await statisticsService.fetchStatisticsTable(data)
      return res
    },
    async fetchAgregats({ rootState }, data) {
      const res = await statisticsService.fetchAgregats(data)
      return res
    },
    async getStatsEtablissementsHebergement({ rootState }, data) {
      const res = await statisticsService.getStatsEtablissementsHebergement(data)
      return res
    },
    async getStatsEtablissementsDeRestauration({ rootState }, data) {
      const res = await statisticsService.getStatsEtablissementsDeRestauration(data)
      return res
    },
    async getStatsFrontieresTerrestres({ rootState }, data) {
      const res = await statisticsService.getStatsFrontieresTerrestres(data)
      return res
    },
    async getStatsEtablissementsDeTransport({ rootState }, data) {
      const res = await statisticsService.getStatsEtablissementsDeTransport(data)
      return res
    },
    async getStatsEtablissementsLocationSansOperateurTransport({ rootState }, data) {
      const res = await statisticsService.getStatsEtablissementsLocationSansOperateurTransport(data)
      return res
    },
    async getStatsEtablissementsDeVoyage({ rootState }, data) {
      const res = await statisticsService.getStatsEtablissementsDeVoyage(data)
      return res
    },
    async getStatsIndustriesTouristiques({ rootState }, data) {
      const res = await statisticsService.getStatsIndustriesTouristiques(data)
      return res
    },
    async updateStatisticsFiltersValues({ rootState, commit }, data) {
      // console.log(data);
      commit('setter_statisticsFiltersValues', data)
    },
  },
}
