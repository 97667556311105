/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { buildFiltersForRequest } from '@/helpers/http-utils'
import {
  listCategoriesSocioPro, listMotifsVoyage, classementEtablissementHebergement, listTypesEtablissementsHebergement,
  listTypesEtablissementsTransport, listMoyensDeTransport,
} from '@/services/utils/constants'
import { BaseService } from './base.service'
import utilsService from '../utils/utils.service'
import formulaireService from './formulaire.service'
import etablissementsService from './etablissements.service'

const {
  countries: listCountries,
  departements: listDepartements, communes: listCommunes, arrondissements: listArrondissements, quartiers: listQuartiers,
} = utilsService.countriesAndPlaces({ stringOnly: false })
export class StatisticsService extends BaseService {
  mainApiRoute = 'statistics'

  stats_exploitation_route = '/statistics-exploitation-etablissements'

  monthsList = utilsService.getListMonths();

  listDepartements = listDepartements

  listCommunes = listCommunes

  listArrondissements = listArrondissements

  listQuartiers = listQuartiers

  listCountries = listCountries

  listCategoriesSocioPro = listCategoriesSocioPro

  listMotifsVoyage = listMotifsVoyage

  listMoyensDeTransport = listMoyensDeTransport

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  fetchStatisticsTable(table, secteur) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/statistics/${table}`, secteur)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchAgregats({ params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/agregats/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // eslint-disable-next-line no-unused-vars
  getStatisticsAgencesVoyageToursOperators(data = {}) {
    const { formType, params } = data
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`${this.mainApiRoute}/inscriptions`, params, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // eslint-disable-next-line no-unused-vars
  getStatisticsTransport(data = {}) {
    const { formType, params } = data
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/statistics-transports', params, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // eslint-disable-next-line no-unused-vars
  getStatisticsAeroports(data = {}) {
    const { formType, params } = data
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/statistics-aeroports', params, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsEtablissementsDeVoyage(options) {
    let { params } = options || {}
    params = params || { filter: { type: [], text: [] } }
    return new Promise(async (resolve, reject) => {
      // this.axiosInstance
      //   .post('/statistics-aeroports', params, {})
      //   .then(response => resolve(response))
      //   .catch(error => reject(error))

      // const listFormMensuellesTransportRequest = await formulaireService.fetchFormulaires({
      //   formType: 'forms-stats-voyages',
      //   params:
      //   {
      //     ...params,
      //     perPage: 100000000000,
      //   },
      // })
      //   .catch(err => {
      //     reject(err)
      //   })

      // get list types ets
      let typeEtabHebergementId = null
      await etablissementsService.getListTypesEtablissement().then(res => {
        // eslint-disable-next-line no-underscore-dangle
        typeEtabHebergementId = ((res.data.resources || []).find(e => e.code === 'agence_voyage') || {})
          ._id || null
      })
        .catch(err => { reject(err) })

      const listEtablissementsTransportRequest = await etablissementsService.findEtablissement({
        params:
        {
          // filter: buildFiltersForRequest({ typeEtablissement: typeEtabHebergementId }),
          filter: { type: [...params.filter.type, 'typeEtablissement'], text: [...params.filter.text, typeEtabHebergementId] },
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // const getFicheMensuellesPerLocation = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportPerLocation(resources)
      //   return result
      // }
      // const getEtabTransportCountPerType = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportCountPerType(resources)
      //   return result
      // }
      const getEtabTransportCountPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabTransportCountPerLocation(resources)
        return result
      }
      // const getFicheMensuellesPerMoyensDeTransport = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportPerMoyenDeTransport(resources)
      //   return result
      // }

      // const processStatsMensuelleEtablissementHebergement = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const statsEtabParClassement = this.processorNombreEtablissementHebergementParClassement(resources)
      //   return { statsEtabParClassement }
      // }

      // const statsFichesMensuellesHotels = processStatsMensuelleEtablissementHebergement(listFormMensuellesTransportRequest)
      // const ficheMensuellesPerMoyensDeTransport = getFicheMensuellesPerMoyensDeTransport(listFormMensuellesTransportRequest)
      const etsCountPerLocation = getEtabTransportCountPerLocation(listEtablissementsTransportRequest)
      // const etsCountPerType = getEtabTransportCountPerType(listEtablissementsTransportRequest)
      // const ficheMensuellePerLocation = getFicheMensuellesPerLocation(listFormMensuellesTransportRequest)
      const toResolve = {
        // statsFichesMensuellesHotels,
        // ficheMensuellesPerMoyensDeTransport,
        etsCountPerLocation,
        // etsCountPerType,
        // ficheMensuellePerLocation,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsIndustriesTouristiques(options) {
    let { params } = options || {}
    params = params || { filter: { type: [], text: [] } }
    return new Promise(async (resolve, reject) => {
      // this.axiosInstance
      //   .post('/statistics-aeroports', params, {})
      //   .then(response => resolve(response))
      //   .catch(error => reject(error))

      // get list types ets
      const listTypesEtablissement = []
      await etablissementsService.getListTypesEtablissement().then(async res => {
        // eslint-disable-next-line no-underscore-dangle
        const tmpTypesEtsList = res.data.resources || []

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < tmpTypesEtsList.length; index++) {
          const { _id, nom } = tmpTypesEtsList[index]
          // eslint-disable-next-line no-await-in-loop
          const countRequest = await etablissementsService.findEtablissement({
            params:
            {
              // filter: buildFiltersForRequest({ typeEtablissement: typeEtabHebergementId }),
              filter: { type: [...params.filter.type, 'typeEtablissement'], text: [...params.filter.text, _id] },
              perPage: 100000000000,
            },
          })
            .catch(err => {
              reject(err)
            })
          // console.log('countRequest :>> ', countRequest)
          const { data } = countRequest || {}
          const { total } = data || {}
          const count = total || 0
          listTypesEtablissement.push({ count, nom })
        }
      })
        .catch(err => { reject(err) })

      // console.log('listTypesEtablissement :>> ', listTypesEtablissement)
      const listEtablissementsTransportRequest = {}

      const getIndustriesTouristiquesCountPerType = itemsList => {
        const result = this.industriesTouristiquesCountPerType(itemsList)
        return result
      }

      const etsCountPerType = getIndustriesTouristiquesCountPerType(listTypesEtablissement)
      const toResolve = {
        etsCountPerType,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsEtablissementsDeTransport(options) {
    let { params } = options || {}
    params = params || { filter: { type: [], text: [] } }
    return new Promise(async (resolve, reject) => {
      // this.axiosInstance
      //   .post('/statistics-aeroports', params, {})
      //   .then(response => resolve(response))
      //   .catch(error => reject(error))

      const listFormMensuellesTransportRequest = await formulaireService.fetchFormulaires({
        formType: 'forms-stats-transports',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // get list types ets
      let typeEtabHebergementId = null
      await etablissementsService.getListTypesEtablissement().then(res => {
        // eslint-disable-next-line no-underscore-dangle
        typeEtabHebergementId = ((res.data.resources || []).find(e => e.code === 'agence_transport' || e.nom === 'Transport de voyageurs') || {})
          ._id || null
      })
        .catch(err => { reject(err) })

      const listEtablissementsTransportRequest = await etablissementsService.findEtablissement({
        params:
        {
          // filter: buildFiltersForRequest({ typeEtablissement: typeEtabHebergementId }),
          filter: { type: [...params.filter.type, 'typeEtablissement'], text: [...params.filter.text, typeEtabHebergementId] },
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      const getFicheMensuellesPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabTransportPerLocation(resources)
        return result
      }
      const getEtabTransportCountPerType = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabTransportCountPerType(resources)
        return result
      }
      const getEtabTransportCountPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabTransportCountPerLocation(resources)
        return result
      }
      const getFicheMensuellesPerMoyensDeTransport = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabTransportPerMoyenDeTransport(resources)
        return result
      }

      // const processStatsMensuelleEtablissementHebergement = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const statsEtabParClassement = this.processorNombreEtablissementHebergementParClassement(resources)
      //   return { statsEtabParClassement }
      // }

      // const statsFichesMensuellesHotels = processStatsMensuelleEtablissementHebergement(listFormMensuellesTransportRequest)
      const ficheMensuellesPerMoyensDeTransport = getFicheMensuellesPerMoyensDeTransport(listFormMensuellesTransportRequest)
      const etsCountPerLocation = getEtabTransportCountPerLocation(listEtablissementsTransportRequest)
      const etsCountPerType = getEtabTransportCountPerType(listEtablissementsTransportRequest)
      const ficheMensuellePerLocation = getFicheMensuellesPerLocation(listFormMensuellesTransportRequest)
      const toResolve = {
        // statsFichesMensuellesHotels,
        ficheMensuellesPerMoyensDeTransport,
        etsCountPerLocation,
        etsCountPerType,
        ficheMensuellePerLocation,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsEtablissementsLocationSansOperateurTransport(options) {
    let { params } = options || {}
    params = params || { filter: { type: [], text: [] } }
    return new Promise(async (resolve, reject) => {
      // this.axiosInstance
      //   .post('/statistics-aeroports', params, {})
      //   .then(response => resolve(response))
      //   .catch(error => reject(error))

      const listFormMensuellesTransportRequest = await formulaireService.fetchFormulaires({
        formType: 'forms-stats-location-vehicule-sans-ops',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // get list types ets
      // let typeEtabHebergementId = null
      // await etablissementsService.getListTypesEtablissement().then(res => {
      //   // eslint-disable-next-line no-underscore-dangle
      //   typeEtabHebergementId = ((res.data.resources || []).find(e => e.code === 'agence_transport' || e.nom === 'Transport de voyageurs') || {})
      //     ._id || null
      // })
      //   .catch(err => { reject(err) })

      // const listEtablissementsTransportRequest = await etablissementsService.findEtablissement({
      //   params:
      //   {
      //     // filter: buildFiltersForRequest({ typeEtablissement: typeEtabHebergementId }),
      //     filter: { type: [...params.filter.type, 'typeEtablissement'], text: [...params.filter.text, typeEtabHebergementId] },
      //     perPage: 100000000000,
      //   },
      // })
      //   .catch(err => {
      //     reject(err)
      //   })

      const getFicheMensuellesPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabLocationSansOperateurPerLocation(resources)
        return result
      }
      // const getEtabTransportCountPerType = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportCountPerType(resources)
      //   return result
      // }
      // const getEtabTransportCountPerLocation = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportCountPerLocation(resources)
      //   return result
      // }
      // const getFicheMensuellesPerMoyensDeTransport = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const result = this.processorEtabTransportPerMoyenDeTransport(resources)
      //   return result
      // }

      // const processStatsMensuelleEtablissementHebergement = requestResponse => {
      //   const { data } = requestResponse
      //   const { resources } = data
      //   const statsEtabParClassement = this.processorNombreEtablissementHebergementParClassement(resources)
      //   return { statsEtabParClassement }
      // }

      // const statsFichesMensuellesHotels = processStatsMensuelleEtablissementHebergement(listFormMensuellesTransportRequest)
      // const ficheMensuellesPerMoyensDeTransport = getFicheMensuellesPerMoyensDeTransport(listFormMensuellesTransportRequest)
      // const etsCountPerLocation = getEtabTransportCountPerLocation(listEtablissementsTransportRequest)
      // const etsCountPerType = getEtabTransportCountPerType(listEtablissementsTransportRequest)
      const ficheMensuellePerLocation = getFicheMensuellesPerLocation(listFormMensuellesTransportRequest)
      const toResolve = {
        // statsFichesMensuellesHotels,
        // ficheMensuellesPerMoyensDeTransport,
        // etsCountPerLocation,
        // etsCountPerType,
        ficheMensuellePerLocation,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsEtablissementsHebergement(options) {
    // console.log('options :>> ', options);
    let { params } = options || {}
    params = params || {}
    return new Promise(async (resolve, reject) => {
      // this.axiosInstance
      //   .post('/statistics-aeroports', params, {})
      //   .then(response => resolve(response))
      //   .catch(error => reject(error))

      const listFormClientHotelRequest = await formulaireService.fetchFormulaires({
        formType: 'forms-clients-hotels',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      const listFormMensuellesHotelRequest = await formulaireService.fetchFormulaires({
        formType: 'forms-stats-hotels',
        params:
        {
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // get list types ets
      let typeEtabHebergementId = null
      await etablissementsService.getListTypesEtablissement().then(res => {
        // eslint-disable-next-line no-underscore-dangle
        typeEtabHebergementId = ((res.data.resources || []).find(e => e.code === 'hotels') || {})
          ._id || null
      })
        .catch(err => { reject(err) })

      const listEtablissementsHebergementRequest = await etablissementsService.findEtablissement({
        params:
        {
          filter: buildFiltersForRequest({ typeEtablissement: typeEtabHebergementId }),
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      const processStatsClientHotel = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorNombreNuitees(resources)
        return result
      }

      const getTypeEtabHebergementPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorTypeEtabHebergementPerLocation(resources)
        return result
      }

      const processStatsMensuelleEtablissementHebergement = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const statsEtabParClassement = this.processorNombreEtablissementHebergementParClassement(resources)
        return { statsEtabParClassement }
      }

      const statsFichesClientes = processStatsClientHotel(listFormClientHotelRequest)
      const statsFichesMensuellesHotels = processStatsMensuelleEtablissementHebergement(listFormMensuellesHotelRequest)
      const nombreEtabHebergementPerLocation = getTypeEtabHebergementPerLocation(listEtablissementsHebergementRequest)
      const toResolve = {
        statsFichesClientes,
        statsFichesMensuellesHotels,
        nombreEtabHebergementPerLocation,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsFrontieresTerrestres(options) {
    let { params } = options || {}
    params = params || {}
    return new Promise(async (resolve, reject) => {
      const listFichesFrontieresTerrestresSortie = await formulaireService.fetchFormulaires({
        formType: 'forms-frontiere-sortie-voyageurs',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })
      const listFichesFrontieresTerrestresEntree = await formulaireService.fetchFormulaires({
        formType: 'forms-frontiere-entree-voyageurs',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // // get list types ets

      const getStatsSortie = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const parPays = this.processorFrontieresTerrestreParPays(resources)
        const parCategorieSocioPro = this.processorSortieFrontieresTerrestreParCategorieSocioPro(resources)
        const parMotifsVoyage = this.processorSortieFrontieresTerrestreParMotifsVoyage(resources)

        return { parPays, parCategorieSocioPro, parMotifsVoyage }
      }

      const getStatsEntree = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const parPays = this.processorFrontieresTerrestreParPays(resources)
        const parCategorieSocioPro = this.processorEntreeFrontieresTerrestreParCategorieSocioPro(resources)
        const parMotifsVoyage = this.processorEntreeFrontieresTerrestreParMotifsVoyage(resources)

        return { parPays, parCategorieSocioPro, parMotifsVoyage }
      }

      const statsSortiesFrontieresTerrestres = getStatsSortie(listFichesFrontieresTerrestresSortie)
      const statsEntreesFrontieresTerrestres = getStatsEntree(listFichesFrontieresTerrestresEntree)
      const { parCategorieSocioPro: parCategorieSocioPro_entree, parMotifsVoyage: parMotifsVoyage_entree } = statsEntreesFrontieresTerrestres
      const { parCategorieSocioPro: parCategorieSocioPro_sortie, parMotifsVoyage: parMotifsVoyage_sortie } = statsSortiesFrontieresTerrestres

      const parCategorieSocioProEntreeEtSortie = parCategorieSocioPro_entree.map(entreeItem => {
        const { name, count: nombreEntrees } = entreeItem
        const nombreSorties = (parCategorieSocioPro_sortie.find(sortieItem => sortieItem.name === name) || { count: 0 }).count
        return {
          name,
          nombreEntrees,
          nombreSorties,
        }
      })
      const parMotifsVoyageEntreeEtSortie = parMotifsVoyage_entree.map(entreeItem => {
        const { name, count: nombreEntrees } = entreeItem
        const nombreSorties = (parMotifsVoyage_sortie.find(sortieItem => sortieItem.name === name) || { count: 0 }).count
        return {
          name,
          nombreEntrees,
          nombreSorties,
        }
      })

      const toResolve = {
        statsSortiesFrontieresTerrestres,
        statsEntreesFrontieresTerrestres,
        parCategorieSocioProEntreeEtSortie,
        parMotifsVoyageEntreeEtSortie,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getStatsEtablissementsDeRestauration(options) {
    let { params } = options || {}
    params = params || {}
    return new Promise(async (resolve, reject) => {
      const listFormMensuellesEtabRestauRequest = await formulaireService.fetchFormulaires({
        formType: 'forms-stats-restaus',
        params:
        {
          ...params,
          perPage: 100000000000,
        },
      })
        .catch(err => {
          reject(err)
        })

      // // get list types ets

      const getStatsEtabRestauPerLocation = requestResponse => {
        const { data } = requestResponse
        const { resources } = data
        const result = this.processorEtabRestauPerLocation(resources)
        return result
      }

      const statsEtabRestauPerLocation = getStatsEtabRestauPerLocation(listFormMensuellesEtabRestauRequest)
      const toResolve = {
        statsEtabRestauPerLocation,
      }

      // resolve response
      resolve(toResolve)
    })
  }

  // eslint-disable-next-line no-unused-vars
  getStatisticsExploitationEtablissements({ data }) {
    // const { params } = data

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`${this.stats_exploitation_route}`, data)
        .then(response => {
          const { data } = response
          const { options, total } = data.resources
          const processedStats = this.perMonthsStatsProcessor(options)
          resolve({ originalResponse: response, processedResponse: processedStats })
        })
        .catch(error => reject(error))
    })
  }

  /**
   *
   * * UTILITIES AND HELPERS
   */

  perMonthsStatsProcessor(data) {
    const statsDatas = [...data]
    const key_annee = 'annee'
    const key_chargeSalariale = 'chargeSalariale'
    const key_chiffreAffaireGlobal = 'chiffreAffaireGlobal'
    const key_mois = 'mois'
    const key_nombreArrives = 'nombreArrives'
    const key_nombreNuites = 'nombreNuites'
    const buildObjectForTotalItems = (key, items) => ({
      [key]: items.reduce((acc, curr) => acc + parseInt(curr[key] || 0), 0),
    })

    let months = [...this.monthsList]
    months = months.map(month => {
      const matchedItems = statsDatas.filter(
        statsDataItem => statsDataItem && statsDataItem[key_mois] === month,
      )
      const result = {
        [key_mois]: month,
        ...buildObjectForTotalItems(key_chargeSalariale, matchedItems),
        ...buildObjectForTotalItems(key_chiffreAffaireGlobal, matchedItems),
        ...buildObjectForTotalItems(key_nombreNuites, matchedItems),
        ...buildObjectForTotalItems(key_nombreArrives, matchedItems),
      }
      return result
    })
    const series = [
      { key: key_chargeSalariale, title: ' Charges Salariales' },
      { key: key_chiffreAffaireGlobal, title: "Chiffre d'affaires" },
      { key: key_nombreNuites, title: 'Nombre de nuitées' },
      { key: key_nombreArrives, title: "Nombre d'arrivées" },
    ].map(serie => ({
      key: serie.key,
      name: serie.title,
      data: months.map(e => e[serie.key] || 0),
    }))
    // console.table("processed Months", series);
    const StatsChiffreAffaire = series.filter(
      e => e.key === key_chargeSalariale,
    )
    const StatsDepensesSalariales = series.filter(
      e => e.key === key_chiffreAffaireGlobal,
    )
    const StatsNombreArrivees = series.filter(
      e => e.key === key_nombreArrives,
    )
    const StatsNombreArrives = series.filter(
      e => e.key === key_nombreArrives,
    )
    const StatsNombreNuites = series.filter(
      e => e.key === key_nombreNuites,
    )
    const StatsGlobalesStackedArea = series
      // .filter(
      //   (e) =>
      //     e.key === key_chiffreAffaireGlobal || e.key === key_chargeSalariale && e.key === key_nombreNuites
      // )
      .map(serie => ({
        ...serie,
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        showSymbol: false,
        lineStyle: {
          width: 0,
        },
      }))
    return {
      months,
      series: {
        StatsChiffreAffaire,
        StatsDepensesSalariales,
        StatsGlobalesStackedArea,
        StatsNombreArrivees,
        StatsNombreNuites,
        StatsNombreArrives,
        series,
      },
    }
  }

  processorNombreNuitees(data) {
    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'

    const formsList = (data && Array.isArray(data)) ? data : []

    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place

        // Form that belongs to this location
        let placeMatches = formsList.filter(formItem => {
          const { etablissement } = formItem
          const formItemLocalisationKey = etablissement && etablissement[queryId]
          return formItemLocalisationKey && formItemLocalisationKey === placeName
        })

        // calculate the nombre nuitees for each form item that match this place
        placeMatches = placeMatches.map(placeMatchFormItem => {
          const { dateArrivee, dateDepart } = placeMatchFormItem
          const dayCount = utilsService.getDayCountBetweenTwoDates({ dates: { first: dateArrivee, second: dateDepart } })
          return { ...placeMatchFormItem, dayCount }
        })

        const nombreTotalNuitees = placeMatches.reduce((acc, curr) => acc + (curr.dayCount || 0),
          0)

        const nombreTotalArrivees = placeMatches.length

        let nombreMoyenNuitees = 0

        if (placeMatches.length !== 0) {
          nombreMoyenNuitees = nombreTotalNuitees / items.length
        }

        // return the place name and the suitable data (total nuitees and others...)
        return {
          name: placeName,
          // nombre total de nuitees
          nombreTotalNuitees,

          // nombre moyen de nuitees
          nombreMoyenNuitees,

          // nombre total d'arrivees
          nombreTotalArrivees,

        }
      })

      const nombreTotalArriveesPerLocalisationType = items.reduce((acc, curr) => acc + (curr.nombreTotalArrivees || 0),
        0)
      const nombreTotalNuiteesPerLocalisationType = items.reduce((acc, curr) => acc + (curr.nombreTotalNuitees || 0),
        0)
      const nombreMoyenNuiteesPerLocalisationType = items.reduce((acc, curr) => acc + (curr.nombreMoyenNuitees || 0),
        0)

      // Calculate percentage
      items = items.map(place => {
        const { nombreTotalArrivees, nombreTotalNuitees, nombreMoyenNuitees } = place
        const pourcentageNombreTotalArrivees = utilsService.calculatePercentage(nombreTotalArrivees, nombreTotalArriveesPerLocalisationType)
        const pourcentageNombreTotalNuitees = utilsService.calculatePercentage(nombreTotalNuitees, nombreTotalNuiteesPerLocalisationType)

        const pourcentageNombreMoyenNuitees = utilsService.calculatePercentage(nombreMoyenNuitees, nombreMoyenNuiteesPerLocalisationType)

        return {
          ...place,
          pourcentageNombreTotalNuitees,
          pourcentageNombreMoyenNuitees,
          pourcentageNombreTotalArrivees,

        }
      })

      return { queryId, items }
    })

    return result
  }

  processorEtabLocationSansOperateurPerLocation(data) {
    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const capacitesAccueilQueryId = 'capacitesAccueil'

    const formsList = (data && Array.isArray(data)) ? data : []

    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place

        // Form that belongs to this location
        let placeMatches = formsList.filter(formItem => {
          const { etablissement } = formItem
          const formItemLocalisationKey = etablissement && etablissement[queryId]
          return formItemLocalisationKey && formItemLocalisationKey === placeName
        })

        // calculate the nombre nuitees for each form item that match this place
        placeMatches = placeMatches.map(placeMatchFormItem => {
          const { capacitesAccueil } = placeMatchFormItem

          const {
            vehiculesDisponiblesJour,
            vehiculesDisponiblesLocations,
            // nombreVehicule,
            // nombreTrains,
            // nombreVoyageursTransporter,
          } = capacitesAccueil
          return {
            ...placeMatchFormItem,
            vehiculesDisponiblesJour,
            vehiculesDisponiblesLocations,
          }
        })

        const vehiculesDisponiblesJour = placeMatches.reduce((acc, curr) => acc + (curr.vehiculesDisponiblesJour || 0), 0)
        const vehiculesDisponiblesLocations = placeMatches.reduce((acc, curr) => acc + (curr.vehiculesDisponiblesLocations || 0), 0)
        // const nombreVehicule = placeMatches.reduce((acc, curr) => acc + (curr.nombreVehicule || 0), 0)
        // const nombreTrains = placeMatches.reduce((acc, curr) => acc + (curr.nombreTrains || 0), 0)
        // const nombreBateaux = placeMatches.reduce((acc, curr) => acc + (curr.nombrePlacesDisponibles || 0), 0)
        // const nombreBateaux = placeMatches.reduce((acc, curr) => acc + (curr.nombrePlacesDisponibles || 0), 0)

        // let nombreMoyenPlacesDisponibles = 0

        // if (placeMatches.length !== 0) {
        //   nombreMoyenPlacesDisponibles = nombreTotalPlacesDisponibles / items.length
        // }

        // return the place name and the suitable data (total nuitees and others...)
        return {
          name: placeName,
          // nombre total de place disponibles
          vehiculesDisponiblesJour,
          vehiculesDisponiblesLocations,
          // nombre moyen de place disponibles
          // nombreMoyenPlacesDisponibles,

        }
      })

      const nombreTotalPlacesDisponiblesPerLocalisation = items.reduce((acc, curr) => acc + (curr.nombreTotalPlacesDisponibles || 0),
        0)

      // // Calculate percentage
      // items = items.map(place => {
      //   const { nombreTotalPlacesDisponibles } = place
      //   const pourcentageNombreTotalPlacesDisponibles = utilsService.calculatePercentage(nombreTotalPlacesDisponibles, nombreTotalPlacesDisponiblesPerLocalisation)
      //   return {
      //     ...place,
      //     pourcentageNombreTotalPlacesDisponibles,

      //   }
      // })

      return { queryId, items }
    })

    return result
  }

  processorEtabTransportPerLocation(data) {
    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const capacitesAccueilQueryId = 'capacitesAccueil'

    const formsList = (data && Array.isArray(data)) ? data : []

    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place

        // Form that belongs to this location
        let placeMatches = formsList.filter(formItem => {
          const { etablissement } = formItem
          const formItemLocalisationKey = etablissement && etablissement[queryId]
          return formItemLocalisationKey && formItemLocalisationKey === placeName
        })

        // calculate the nombre nuitees for each form item that match this place
        placeMatches = placeMatches.map(placeMatchFormItem => {
          const { capacitesAccueil } = placeMatchFormItem

          const {
            nombreAeronefs,
            nombreBateaux,
            nombreVehicule,
            nombreTrains,
            nombreVoyageursTransporter,
          } = capacitesAccueil
          return {
            ...placeMatchFormItem,
            nombreAeronefs,
            nombreBateaux,
            nombreVehicule,
            nombreTrains,
          }
        })

        const nombreAeronefs = placeMatches.reduce((acc, curr) => acc + (curr.nombreAeronefs || 0), 0)
        const nombreBateaux = placeMatches.reduce((acc, curr) => acc + (curr.nombreBateaux || 0), 0)
        const nombreVehicule = placeMatches.reduce((acc, curr) => acc + (curr.nombreVehicule || 0), 0)
        const nombreTrains = placeMatches.reduce((acc, curr) => acc + (curr.nombreTrains || 0), 0)
        // const nombreBateaux = placeMatches.reduce((acc, curr) => acc + (curr.nombrePlacesDisponibles || 0), 0)
        // const nombreBateaux = placeMatches.reduce((acc, curr) => acc + (curr.nombrePlacesDisponibles || 0), 0)

        // let nombreMoyenPlacesDisponibles = 0

        // if (placeMatches.length !== 0) {
        //   nombreMoyenPlacesDisponibles = nombreTotalPlacesDisponibles / items.length
        // }

        // return the place name and the suitable data (total nuitees and others...)
        return {
          name: placeName,
          // nombre total de place disponibles
          nombreAeronefs,
          nombreBateaux,
          nombreVehicule,
          nombreTrains,
          // nombre moyen de place disponibles
          // nombreMoyenPlacesDisponibles,

        }
      })

      const nombreTotalPlacesDisponiblesPerLocalisation = items.reduce((acc, curr) => acc + (curr.nombreTotalPlacesDisponibles || 0),
        0)

      // // Calculate percentage
      // items = items.map(place => {
      //   const { nombreTotalPlacesDisponibles } = place
      //   const pourcentageNombreTotalPlacesDisponibles = utilsService.calculatePercentage(nombreTotalPlacesDisponibles, nombreTotalPlacesDisponiblesPerLocalisation)
      //   return {
      //     ...place,
      //     pourcentageNombreTotalPlacesDisponibles,

      //   }
      // })

      return { queryId, items }
    })

    return result
  }

  processorEtabRestauPerLocation(data) {
    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const capacitesAccueilQueryId = 'capacitesAccueil'

    const formsList = (data && Array.isArray(data)) ? data : []

    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place

        // Form that belongs to this location
        let placeMatches = formsList.filter(formItem => {
          const { etablissement } = formItem
          const formItemLocalisationKey = etablissement && etablissement[queryId]
          return formItemLocalisationKey && formItemLocalisationKey === placeName
        })

        // calculate the nombre nuitees for each form item that match this place
        placeMatches = placeMatches.map(placeMatchFormItem => {
          const { capacitesAccueil } = placeMatchFormItem
          const { nombrePlaces: nombrePlacesDisponibles } = capacitesAccueil
          return { ...placeMatchFormItem, nombrePlacesDisponibles }
        })

        const nombreTotalPlacesDisponibles = placeMatches.reduce((acc, curr) => acc + (curr.nombrePlacesDisponibles || 0),
          0)

        let nombreMoyenPlacesDisponibles = 0

        if (placeMatches.length !== 0) {
          nombreMoyenPlacesDisponibles = nombreTotalPlacesDisponibles / items.length
        }

        // return the place name and the suitable data (total nuitees and others...)
        return {
          name: placeName,
          // nombre total de place disponibles
          nombreTotalPlacesDisponibles,

          // nombre moyen de place disponibles
          nombreMoyenPlacesDisponibles,

        }
      })

      const nombreTotalPlacesDisponiblesPerLocalisation = items.reduce((acc, curr) => acc + (curr.nombreTotalPlacesDisponibles || 0),
        0)

      // Calculate percentage
      items = items.map(place => {
        const { nombreTotalPlacesDisponibles } = place
        const pourcentageNombreTotalPlacesDisponibles = utilsService.calculatePercentage(nombreTotalPlacesDisponibles, nombreTotalPlacesDisponiblesPerLocalisation)
        return {
          ...place,
          pourcentageNombreTotalPlacesDisponibles,

        }
      })

      return { queryId, items }
    })
    return result
  }

  processorEtabTransportCountPerLocation(data) {
    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const capacitesAccueilQueryId = 'capacitesAccueil'

    const listEtablissement = (data && Array.isArray(data)) ? data : []
    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place

        // Form that belongs to this location
        const nbrEts = listEtablissement.filter(formItem => formItem[queryId] === placeName).length
        // return the place name and the suitable data (total nuitees and others...)
        return {
          name: placeName,
          nbrEts,

        }
      })

      const nombreTotalEtsTransport = items.reduce((acc, curr) => acc + (curr.nbrEts || 0),
        0)

      return { queryId, items }
    })
    return result
  }

  processorEtabTransportPerMoyenDeTransport(data) {
    const capacitesAccueilQueryId = 'capacitesAccueil'
    const { turnToSnakeCase, turnToCamelCase } = utilsService
    const moyensDeTransport = listMoyensDeTransport.map(e => ({
      id: turnToSnakeCase(e),
      name: e,
      [turnToCamelCase(`nombre ${e}`)]: 0,
      nombrePlacesDisponibles: 0,
      nombreVoyageursTransporter: 0,
    }))

    const formList = (data && Array.isArray(data)) ? data : []
    // console.log('formList :>> ', formList)
    const result = moyensDeTransport.map(MDT => {
      const {
        name: nameMDT,
      } = MDT

      const queryId = turnToCamelCase(`nombre ${nameMDT}`)

      formList.forEach(formItem => {
        const {
          capacitesAccueil, etablissement,
        } = formItem

        const {
          sousTypeEtablissement,
        } = etablissement || {}

        const {
          nombrePlacesDisponibles,
          nombreVoyageursTransporter,
        } = capacitesAccueil || {}

        if (capacitesAccueil[queryId]) {
          MDT[queryId] += capacitesAccueil[queryId]
          MDT.nombrePlacesDisponibles += nombrePlacesDisponibles
          MDT.nombreVoyageursTransporter += nombreVoyageursTransporter
        }
      })

      return {
        ...MDT,
      }
    })

    // console.log('result :>> ', result)
    return result
  }

  industriesTouristiquesCountPerType(data) {
    const listTypesEts = (data && Array.isArray(data)) ? data : []
    // console.log('listTypesEts :>> ', listTypesEts)
    const { turnToSnakeCase } = utilsService
    const typesEtablissementsTransport = listTypesEtablissementsTransport.map(e => ({ id: turnToSnakeCase(e), name: e, count: 0 }))

    const sousTypeEtablissementQueryId = 'sousTypeEtablissement'

    const series = listTypesEts.map(e => e.count || 0)
    const labels = listTypesEts.map(e => e.nom || '')
    const result = {
      // ...resultPerType,
      series,
      labels,

    }
    return result
  }

  processorEtabTransportCountPerType(data) {
    const listEtablissement = (data && Array.isArray(data)) ? data : []
    const { turnToSnakeCase } = utilsService
    const typesEtablissementsTransport = listTypesEtablissementsTransport.map(e => ({ id: turnToSnakeCase(e), name: e, count: 0 }))

    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const sousTypeEtablissementQueryId = 'sousTypeEtablissement'

    const resultPerType = typesEtablissementsTransport.map(typeEts => {
      listEtablissement.forEach(etablissement => {
        const belongsType = etablissement && etablissement[sousTypeEtablissementQueryId] === typeEts.name
        if (belongsType) {
          typeEts.count += 1
        }
      })
      return { [typeEts.id]: typeEts.count }
    }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
    const series = Object.values(resultPerType)
    const labels = listTypesEtablissementsTransport
    const result = {
      ...resultPerType,
      series,
      labels,

    }
    return result
  }

  processorTypeEtabHebergementPerLocation(data) {
    const listEtablissementHebergement = (data && Array.isArray(data)) ? data : []
    const { turnToSnakeCase } = utilsService

    const classements = classementEtablissementHebergement.map(e => ({ id: turnToSnakeCase(e), label: e }))
    const typesEtablissementsHebergement = listTypesEtablissementsHebergement.map(e => ({ id: turnToSnakeCase(e), name: e }))

    const departementQueryId = 'departement'
    const communeQueryId = 'commune'
    const arrondissementQueryId = 'arrondissement'
    const quartierQueryId = 'quartier'
    const sousTypeEtablissementQueryId = 'sousTypeEtablissement'

    const localisations = [
      {
        queryId: departementQueryId,
        items: this.listDepartements,
      },
      {
        queryId: communeQueryId,
        items: this.listCommunes,
      },
      {
        queryId: arrondissementQueryId,
        items: this.listArrondissements,
      },
      {
        queryId: quartierQueryId,
        items: this.listQuartiers,
      },
    ]

    const result = localisations.map(localisationItem => {
      let { items } = localisationItem
      const { queryId } = localisationItem
      items = items.map(place => {
        const { name: placeName } = place
        const typesEtsIds = typesEtablissementsHebergement.map(e => e.id)
        typesEtsIds.forEach(e => {
          // eslint-disable-next-line no-param-reassign
          place[e] = 0
        })
        // loop on types ets
        typesEtablissementsHebergement.forEach(typeEts => {
          listEtablissementHebergement.forEach(etablissement => {
            const belongToPlace = etablissement && etablissement[queryId] === placeName
            const belongsType = etablissement && etablissement[sousTypeEtablissementQueryId] === typeEts.name
            if (belongToPlace && belongsType) {
              place[typeEts.id] += 1
            }
          })
        })
        // return the place name and the suitable data (total nuitees and others...)
        return place
      })
      return { queryId, items }
    })
    return result
  }

  processorNombreEtablissementHebergementParClassement(data) {
    const sousTypeEtablissementQueryId = 'sousTypeEtablissement'
    const classementQueryId = 'classement'
    let listFichesMensuellesEtabHebergement = (data && Array.isArray(data)) ? data : []
    const { turnToSnakeCase } = utilsService

    let classements = classementEtablissementHebergement.map(e => ({ id: turnToSnakeCase(e), label: e }))
    const typesEtablissementsHebergement = listTypesEtablissementsHebergement.map(e => ({ id: turnToSnakeCase(e), name: e }))
    const typesEtsIds = typesEtablissementsHebergement.map(e => e.id)

    listFichesMensuellesEtabHebergement = listFichesMensuellesEtabHebergement.map(e => ({ ...e, classement: turnToSnakeCase(e.classement) }))
    classements = classements.map(rank => {
      typesEtsIds.forEach(e => {
        // eslint-disable-next-line no-param-reassign
        rank[e] = 0
      })

      typesEtablissementsHebergement.forEach(typeEts => {
        listFichesMensuellesEtabHebergement.forEach(fiche => {
          const { etablissement } = fiche
          const belongToRank = fiche[classementQueryId] === rank.id
          const belongsType = etablissement && etablissement[sousTypeEtablissementQueryId] === typeEts.name
          if (belongToRank && belongsType) {
            rank[typeEts.id] += 1
          }
        })
      })
      return rank
    })

    return { classements, typesEtablissementsHebergement, intersections: [] }
  }

  processorEntreeFrontieresTerrestreParMotifsVoyage(data) {
    const formsList = (data && Array.isArray(data)) ? data : []
    let motifsVoyage = [...this.listMotifsVoyage].map(e => ({ name: e }))

    motifsVoyage = motifsVoyage.map(motif => ({
      ...motif, count: 0, totalDureeSejour: 0,
    }))

    motifsVoyage = motifsVoyage.map(motif => {
      let {
        name: motifName,
        count,
        totalDureeSejour,

      } = motif

      const matchWithMotif = val => val === motifName

      formsList.forEach(formItem => {
        const { dureeSejour, motifVoyage } = formItem

        if (matchWithMotif(motifVoyage)) {
          count += 1

          totalDureeSejour += dureeSejour
        }
      })

      return {
        ...motif,
        count,
        totalDureeSejour,
      }
    })

    // Calcule des moyenne
    const sommeTotalDureeSejour = motifsVoyage.reduce((acc, curr) => acc + (curr.totalDureeSejour || 0), 0)

    motifsVoyage = motifsVoyage.map(motif => {
      const {

        totalDureeSejour,
      } = motif
      const moyenneDureeSejour = this.processIsNaN(totalDureeSejour / sommeTotalDureeSejour)

      return {
        ...motif,
        moyenneDureeSejour,

      }
    })

    const result = motifsVoyage
    return result
  }

  processorSortieFrontieresTerrestreParMotifsVoyage(data) {
    const formsList = (data && Array.isArray(data)) ? data : []
    let motifsVoyage = [...this.listMotifsVoyage].map(e => ({ name: e }))

    motifsVoyage = motifsVoyage.map(motif => ({
      ...motif, count: 0, totalDureeSejourResident: 0, totalDureeSejourNonResident: 0, moyenneDureeSejourResident: 0,
    }))

    motifsVoyage = motifsVoyage.map(motif => {
      let {
        name: motifName,
        count,
        totalDureeSejourResident,
        totalDureeSejourNonResident,

      } = motif

      const matchWithMotif = val => val === motifName

      formsList.forEach(formItem => {
        const { categorieSocioPro, paysResidence } = formItem
        const isBeninResident = this.isBeninResident(paysResidence)
        const sejourInfosKey = isBeninResident ? 'sejourEtranger' : 'sejourBenin'
        const { dureeSejour, motifVoyage } = formItem[sejourInfosKey] || { dureeSejour: 0 }

        if (matchWithMotif(motifVoyage)) {
          count += 1

          if (isBeninResident) totalDureeSejourResident += dureeSejour
          if (!isBeninResident) totalDureeSejourNonResident += dureeSejour
        }
      })

      return {
        ...motif,
        count,
        totalDureeSejourResident,
        totalDureeSejourNonResident,
        totalDureeSejour: totalDureeSejourResident + totalDureeSejourNonResident,
      }
    })

    // Calcule des moyenne
    const sommeTotalDureeSejour = motifsVoyage.reduce((acc, curr) => acc + (curr.totalDureeSejour || 0), 0)
    const sommeTotalDureeSejourResident = motifsVoyage.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)
    const sommeTotalDureeSejourNonResident = motifsVoyage.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)

    motifsVoyage = motifsVoyage.map(cat => {
      const {
        totalDureeSejourResident,
        totalDureeSejourNonResident,
        totalDureeSejour,
      } = cat
      const moyenneDureeSejour = this.processIsNaN(totalDureeSejour / sommeTotalDureeSejour)
      const moyenneDureeSejourResident = this.processIsNaN(totalDureeSejourResident / sommeTotalDureeSejourResident)
      const moyenneDureeSejourNonResident = this.processIsNaN(totalDureeSejourNonResident / sommeTotalDureeSejourNonResident)

      return {
        ...cat,
        moyenneDureeSejour,
        moyenneDureeSejourResident,
        moyenneDureeSejourNonResident,
      }
    })

    const result = motifsVoyage
    return result
  }

  processorSortieFrontieresTerrestreParCategorieSocioPro(data) {
    const formsList = (data && Array.isArray(data)) ? data : []
    let categoriesSocioPro = [...this.listCategoriesSocioPro].map(e => ({ name: e }))

    categoriesSocioPro = categoriesSocioPro.map(cat => ({
      ...cat, count: 0, totalDureeSejourResident: 0, totalDureeSejourNonResident: 0, moyenneDureeSejourResident: 0,
    }))

    categoriesSocioPro = categoriesSocioPro.map(cat => {
      let {
        name: catName,
        count,
        totalDureeSejourResident,
        totalDureeSejourNonResident,

      } = cat

      const matchWithCat = val => val === catName

      formsList.forEach(formItem => {
        const { categorieSocioPro, paysResidence } = formItem

        if (matchWithCat(categorieSocioPro)) {
          count += 1

          const isBeninResident = this.isBeninResident(paysResidence)
          const sejourInfosKey = isBeninResident ? 'sejourEtranger' : 'sejourBenin'
          const { dureeSejour } = formItem[sejourInfosKey] || { dureeSejour: 0 }

          if (isBeninResident) totalDureeSejourResident += dureeSejour
          if (!isBeninResident) totalDureeSejourNonResident += dureeSejour
        }
      })

      return {
        ...cat,
        count,
        totalDureeSejourResident,
        totalDureeSejourNonResident,
        totalDureeSejour: totalDureeSejourResident + totalDureeSejourNonResident,
      }
    })

    // Calcule des moyenne
    const sommeTotalDureeSejour = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejour || 0), 0)
    const sommeTotalDureeSejourResident = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)
    const sommeTotalDureeSejourNonResident = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)

    categoriesSocioPro = categoriesSocioPro.map(cat => {
      const {
        totalDureeSejourResident,
        totalDureeSejourNonResident,
        totalDureeSejour,
      } = cat
      const moyenneDureeSejour = this.processIsNaN(totalDureeSejour / sommeTotalDureeSejour)
      const moyenneDureeSejourResident = this.processIsNaN(totalDureeSejourResident / sommeTotalDureeSejourResident)
      const moyenneDureeSejourNonResident = this.processIsNaN(totalDureeSejourNonResident / sommeTotalDureeSejourNonResident)

      return {
        ...cat,
        moyenneDureeSejour,
        moyenneDureeSejourResident,
        moyenneDureeSejourNonResident,
      }
    })

    const result = categoriesSocioPro
    return result
  }

  processorEntreeFrontieresTerrestreParCategorieSocioPro(data) {
    const formsList = (data && Array.isArray(data)) ? data : []
    let categoriesSocioPro = [...this.listCategoriesSocioPro].map(e => ({ name: e }))

    categoriesSocioPro = categoriesSocioPro.map(cat => ({
      ...cat, count: 0, totalDureeSejour: 0,
    }))

    categoriesSocioPro = categoriesSocioPro.map(cat => {
      let {
        name: catName,
        count,
        // totalDureeSejourResident,
        // totalDureeSejourNonResident,
        totalDureeSejour,

      } = cat

      const matchWithCat = val => val === catName

      formsList.forEach(formItem => {
        const { categorieSocioPro, dureeSejour } = formItem
        if (matchWithCat(categorieSocioPro)) {
          count += 1
          totalDureeSejour += dureeSejour
          // if (!isBeninResident) totalDureeSejourNonResident += dureeSejour
        }
      })

      return {
        ...cat,
        count,
        // totalDureeSejourResident,
        // totalDureeSejourNonResident,
        totalDureeSejour,
      }
    })

    // Calcule des moyenne
    const sommeTotalDureeSejour = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejour || 0), 0)
    // const sommeTotalDureeSejourResident = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)
    // const sommeTotalDureeSejourNonResident = categoriesSocioPro.reduce((acc, curr) => acc + (curr.totalDureeSejourNonResident || 0), 0)

    categoriesSocioPro = categoriesSocioPro.map(cat => {
      const {
        totalDureeSejourResident,
        totalDureeSejourNonResident,
        totalDureeSejour,
      } = cat
      const moyenneDureeSejour = this.processIsNaN(totalDureeSejour / sommeTotalDureeSejour)

      return {
        ...cat,
        moyenneDureeSejour,
        // moyenneDureeSejourResident,
        // moyenneDureeSejourNonResident,
      }
    })

    const result = categoriesSocioPro
    return result
  }

  processorFrontieresTerrestreParPays(data) {
    const sousTypeEtablissementQueryId = 'sousTypeEtablissement'
    const nationaliteQueryId = 'nationalite'
    const paysDestinationQueryId = 'paysDestination'
    const paysResidenceQueryId = 'paysResidence'

    const formsList = (data && Array.isArray(data)) ? data : []

    let countries = [...this.listCountries]

    countries = countries.map(country => ({
      ...country,
      provenance: 0,
      residence: 0,
      destination: 0,
      totalDureeSejourProvenance: 0,
      totalDureeSejouResidence: 0,
      totalDureeSejourDestination: 0,
    }))

    countries = countries.map(countrie => {
      let {
        name: countryName,
        // pays
        provenance,
        residence,
        destination,

        // duree total sejour
        totalDureeSejourProvenance,
        totalDureeSejouResidence,
        totalDureeSejourDestination,

      } = countrie

      const matchWithCOuntry = val => val === countryName

      formsList.forEach(formItem => {
        const {
          nationalite, paysDestination, paysProvenance, paysResidence,
        } = formItem
        const isBeninResident = this.isBeninResident(paysResidence)
        const sejourInfosKey = isBeninResident ? 'sejourEtranger' : 'sejourBenin'
        const { dureeSejour } = formItem[sejourInfosKey] || { dureeSejour: 0 }
        if (matchWithCOuntry(nationalite)) {
          residence += 1
          totalDureeSejouResidence += dureeSejour
        }
        if (matchWithCOuntry(paysDestination)) {
          destination += 1
          totalDureeSejourDestination += dureeSejour
        }
        if (matchWithCOuntry(paysProvenance)) {
          provenance += 1
          totalDureeSejourProvenance += dureeSejour
        }
      })

      return {
        ...countrie,
        provenance,
        residence,
        destination,
        totalDureeSejourProvenance,
        totalDureeSejouResidence,
        totalDureeSejourDestination,
      }
    })

    const result = countries

    return result
  }

  isBeninResident(country) {
    if (country && country.name) return country.name === 'Bénin'
    return country && country === 'Bénin'
  }

  processIsNaN(val) {
    return isNaN(val) ? 0 : val
  }
}

export default new StatisticsService()
